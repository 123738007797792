import React, { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import Menu from './components/Menu';
import { fetchButtons } from './api/sheetAPI';

function App() {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const buttonsData = await fetchButtons();
      if (buttonsData) {
        setButtons(buttonsData);
      }
    };
    fetchData();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Menu buttons={buttons} />
    </ChakraProvider>
  );
}

export default App;
