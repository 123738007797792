import React from 'react';
import { Box, VStack, Button, Text, HStack, Icon, Link } from '@chakra-ui/react';
import { FaTwitter, FaInstagram, FaWhatsapp , FaBehance, FaLinkedin } from 'react-icons/fa';
import '../styles/Menu.css';

function Menu({ buttons }) {
  return (
    <Box bg="gray.700" p={4} className="menu-box">
      <Box textAlign="center" mb={4}>
        <Text fontSize="xl" fontWeight="bold">Erdoğan Matrak</Text>
        <Text fontSize="md">Onixa3dStudio & Polyverse</Text>
        <HStack justify="center" spacing={4} mt={3}>
          <Link href="https://twitter.com/matrakim" isExternal>
            <Icon as={FaTwitter} w={6} h={6} />
          </Link>
          <Link href="https://www.instagram.com/onixa3dstudio/" isExternal>
            <Icon as={FaInstagram} w={6} h={6} />
          </Link>
          <Link href="https://wa.me/905317071991" isExternal>
            <Icon as={FaWhatsapp} w={6} h={6} />
            </Link>
          <Link href="https://www.behance.net/erdoanmatrak" isExternal>
            <Icon as={FaBehance} w={6} h={6} />
          </Link>
          <Link href="https://www.linkedin.com/in/erdoganmatrak/" isExternal>
            <Icon as={FaLinkedin} w={6} h={6} />
          </Link>
        </HStack>
      </Box>
      <VStack spacing={4} align="stretch">
        {buttons && buttons.map((button, index) => (
          <Button
            key={index}
            variant="outline"
            borderRadius="full"
            borderWidth="2px"
            borderColor="black"
            bg="white"
            boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
            _hover={{
              bg: "gray.100",
              boxShadow: "0 5px 10px -1px rgba(0, 0, 0, 0.15), 0 3px 6px -2px rgba(0, 0, 0, 0.1)"
            }}
            _active={{
              bg: "gray.200",
              transform: "scale(0.98)",
              borderColor: "black",
              boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.2), 0 1px 2px -2px rgba(0, 0, 0, 0.1)"
            }}
            onClick={() => window.open(button.url, "_blank")}
          >
            {button.name}
          </Button>
        ))}
      </VStack>
      <Box mb={5} />
      <Box textAlign="center" mb={4} fontSize="sm">
        <Text>
          İletişim: <Link href="mailto:erdoganmatrak20@gmail.com" color="blue.200" isExternal>erdoganmatrak20@gmail.com</Link>
        </Text>
        <Text>
          Adres: <Link href="https://maps.app.goo.gl/43UQQY6nSwYj5orZ6" color="blue.200" isExternal>Çamlaraltı Mah. 6005. No:1 Gül Apt.</Link>
        </Text>
      </Box>
      
    </Box>
  );
}

export default Menu;
