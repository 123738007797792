import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      // Koyu tema için genel arka plan rengini belirleyin
      body: {
        bg: 'gray.800',
        color: 'white',
      },
      fonts: {
        heading: '"Raleway", sans-serif', // Başlık fontu olarak Raleway'i kullan
        body: '"Open Sans", sans-serif', // Gövde metni fontu olarak Open Sans'ı kullan
      },
    },
  },
});

export default theme;
