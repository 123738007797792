import axios from 'axios';

const apiEndpoint = 'https://sheetdb.io/api/v1/kcr7mag8v9xhg';

export const fetchButtons = async () => {
  try {
    const response = await axios.get(apiEndpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
